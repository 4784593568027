import { useRequest } from '@shared-ui/hooks'
import { didUserAbortedError } from '@shared-ui/hooks/useRequest'
import { MAC_LENGTH, parseMacOrSerialInput, validateMacSerial } from '@shared/lib'
import type { KitReturnDevice, UaG2KitReturn } from '@shared/types/devices'
import { CheckmarkIconL } from '@ubnt/icons'
import { Button, Input, Radio, Text, cssVariables } from '@ubnt/ui-components'
import type { ChangeEvent } from 'react'
import { useState } from 'react'
import { KitReturnService } from '../../kit-return-service'
import { useSubmitRmaStore } from '../../submit-rma-store'

export function UaG2ReturnConfirmation({ kitReturn }: { kitReturn: UaG2KitReturn }) {
  const { device, setKitReturn } = useSubmitRmaStore()
  const [changeIndex, setChangeIndex] = useState(1)
  const [skipOption] = useState(kitReturn.option && kitReturn.option !== 'not-part-of-kit')

  const skipMacs = device?.isUnifiCare
  const sku = device?.coreItemCode?.toLowerCase() || ''
  const showMacInputs = !skipMacs && kitReturn.option && kitReturn.option !== 'not-part-of-kit'

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value !== 'on' || !device) {
      return
    }

    const returnOptionNew = event.currentTarget.id as UaG2KitReturn['option']
    const devices = skipMacs ? [] : KitReturnService.createKitDevices(returnOptionNew === 'ua-g2-sk-pro' ? 2 : 1)

    setKitReturn({
      type: 'ua-g2',
      option: returnOptionNew,
      returnSpecifics: 'whole-kit',
      devices,
    })

    setChangeIndex(changeIndex + 1)
  }

  return (
    <div className="flex column s-8 mt-18">
      {!skipOption && (
        <>
          <Text size="body" weight="bold" className="my-8">
            Is this product part of a kit?
          </Text>

          <div className="inline-flex">
            <Radio
              id="not-part-of-kit"
              variant="primary"
              checked={kitReturn.option === 'not-part-of-kit'}
              onChange={handleOptionChange}
            >
              No
            </Radio>
          </div>

          {sku.indexOf('ua-g2-pro') === -1 && (
            <div className="inline-flex">
              <Radio
                id="ua-g2-sk"
                variant="primary"
                checked={kitReturn.option === 'ua-g2-sk'}
                onChange={handleOptionChange}
              >
                Yes (UA-G2-SK)
              </Radio>
            </div>
          )}

          {sku.indexOf('ua-g2-sk') === -1 && (
            <div className="inline-flex">
              <Radio
                id="ua-g2-sk-pro"
                variant="primary"
                checked={kitReturn.option === 'ua-g2-sk-pro'}
                onChange={handleOptionChange}
              >
                Yes (UA-G2-SK-PRO)
              </Radio>
            </div>
          )}
        </>
      )}

      {showMacInputs && (
        <>
          <Text size="body" className="mt-12">
            Please enter the MAC ID(s) of the other kit item(s) so we can gather info on them.
          </Text>

          <div key={changeIndex} className="s-8">
            {kitReturn.devices.map((entry, index) => {
              return <CustomMac key={index} kitDevice={entry} kitReturn={kitReturn} />
            })}
          </div>
        </>
      )}
    </div>
  )
}

const CustomMac = ({ kitDevice, kitReturn }: { kitDevice: KitReturnDevice; kitReturn: UaG2KitReturn }) => {
  const [mac, setMac] = useState('')
  const [validationError, setValidationError] = useState('')

  const [validateMacExternal, { loading, error }] = useRequest('validateKitMac', {
    abortTimeout: 60 * 1000,
    retries: 0,
  })

  const showCheckmark = !kitDevice.loading && kitDevice.mac && !kitDevice.error

  const handleSetMac = (element: HTMLInputElement, newMac: string) => {
    setMac(newMac)
    setValidationError('')

    const parsedMac = parseMacOrSerialInput(newMac)
    if (parsedMac.length >= MAC_LENGTH || validateMacSerial(newMac)) {
      element.blur()
    }
  }

  const handleMacSearch = async (newMac: string) => {
    if (loading || kitDevice.mac === newMac) {
      return
    }

    KitReturnService.updateKitDevice(kitDevice.index, {
      deviceId: null,
      sku: '',
      mac: newMac,
      error: '',
      loading: false,
    })

    if (!newMac) {
      return
    }

    const hadError = KitReturnService.revalidateAllMacs(kitDevice.index)
    if (hadError) {
      return
    }

    try {
      KitReturnService.updateKitDevice(kitDevice.index, {
        mac: newMac,
        loading: true,
      })

      const result = await validateMacExternal({
        mac: newMac,
        kitType: kitReturn.type,
        kitOption: kitReturn.option || '',
      })

      KitReturnService.updateKitDevice(kitDevice.index, {
        deviceId: result.device?.id || 0,
        sku: result.device?.sku || '',
        loading: false,
      })

      KitReturnService.revalidateAllSkus()
    } catch (err) {
      const userAborted = didUserAbortedError(err)
      if (userAborted) {
        KitReturnService.updateKitDevice(kitDevice.index, {
          loading: false,
        })
      } else {
        KitReturnService.updateKitDevice(kitDevice.index, {
          error: err instanceof Error ? err.message : 'Unknown Error',
          loading: false,
        })
      }
    }
  }

  return (
    <div>
      <div className="relative height-32">
        <div className="flex flex-1 align-center">
          <Input
            variant="tertiary"
            value={mac}
            onChange={(event, value) => {
              handleSetMac(event.currentTarget, String(value))
            }}
            disabled={loading}
            inputContainerClassName="my-6 bg-gray border-radius"
            width="100%"
            placeholder="MAC ID"
            onBlur={(event) => void handleMacSearch(event.currentTarget.value)}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                event.currentTarget.blur()
              }
            }}
          />

          <div className="absolute right top height-100 flex flex-basis align-center px-8 my-6 pointer-events-none">
            {loading && (
              <>
                <Text size="body" color="tertiary" className="px-8">
                  Searching
                </Text>
                <Button loader="dots" />
              </>
            )}

            {showCheckmark && (
              <CheckmarkIconL color={cssVariables['color-success']} variant="fill" width="20" height="20" />
            )}
          </div>
        </div>
      </div>

      {!kitDevice.loading && (validationError || kitDevice.error || error) && (
        <div className="mt-8">
          <Text size="body" color="danger">
            {validationError || kitDevice.error || error}
          </Text>
        </div>
      )}
    </div>
  )
}
