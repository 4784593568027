import { useRequest } from '@shared-ui/hooks'
import { Button, Text } from '@ubnt/ui-components'
import { useState } from 'react'
import { getReplacementPartSku } from 'rma-shared/lib/utils/device'
import { SubmitRmaService } from '../submit-rma-service'
import { useSubmitRmaStore } from '../submit-rma-store'
import { UCareGradient } from './u-care'
import { DevicePartsReturn } from './device-parts-return'
import { ReturnConfirmation } from './kits/return-confirmation'
import { NotMyDevice } from './not-my-device'
import { RmaDevicePanel } from './rma-device-panel'
import { StepHeading } from './step-heading'
import { SubmitContainer } from './submit-container'
import { UCareBadge } from './u-care-badge'

export function DeviceFound() {
  const { device, currSku, deviceStateCurr, deviceStatePrev, submitAsReplacementPart, isInputMac } = useSubmitRmaStore()
  const [notMyDevice, setNotMyDevice] = useState(false)

  const [updateDeviceKit, { loading: updateDeviceKitLoading, error }] = useRequest('updateDeviceKit')
  const [updateReplacementPart, { loading: updateReplacementPartLoading }] = useRequest('updateReplacementPart')

  const handleNotMyDevice = () => {
    setNotMyDevice(true)

    useSubmitRmaStore.setState({
      deviceStateCurr: {
        kitReturn: null,
      },
      deviceStatePrev: deviceStateCurr,
    })
  }

  const handleNotMyDeviceBack = () => {
    setNotMyDevice(false)

    if (deviceStatePrev) {
      useSubmitRmaStore.setState({
        deviceStateCurr: deviceStatePrev,
        deviceStatePrev: null,
      })
    }
  }

  if (!device) {
    return null
  }

  if (notMyDevice) {
    return (
      <SubmitContainer>
        <NotMyDevice onBack={handleNotMyDeviceBack} />
      </SubmitContainer>
    )
  }

  const isLoading = updateDeviceKitLoading || updateReplacementPartLoading
  const isNextDisabled = !SubmitRmaService.canStartFlow() || isLoading
  const replacementPartSku = getReplacementPartSku(device)
  const showReplacementPartSku = !!replacementPartSku

  const handleNext = async () => {
    try {
      if (deviceStateCurr.kitReturn) {
        const result = await updateDeviceKit({ deviceId: device.id, kitReturn: deviceStateCurr.kitReturn })
        SubmitRmaService.processDevice(result.device)
        SubmitRmaService.startFlow()
      } else if (submitAsReplacementPart) {
        const result = await updateReplacementPart({ deviceId: device.id })
        SubmitRmaService.processDevice(result.device)
        SubmitRmaService.startFlow()
      } else {
        SubmitRmaService.startFlow()
      }
    } catch (err) {
      //
    }
  }

  return (
    <SubmitContainer>
      {device.isUnifiCare && (
        <>
          <UCareGradient />
          <UCareBadge />
        </>
      )}

      <div className="flex column relative p-16">
        <StepHeading showStepper heading="Is this your device?" />

        <Text size="body" color="secondary" className="mt-8">
          Please ensure the model and {isInputMac ? 'MAC address' : 'serial number'} match your device.
        </Text>

        <RmaDevicePanel device={device} skuOverride={currSku} />

        {showReplacementPartSku && (
          <div className="mt-12">
            <DevicePartsReturn sku={device.name} partSku={replacementPartSku} />
          </div>
        )}

        {!device.isUnifiCare && !device.isAdvancedFlowAvailable && (
          <div className="mt-4">
            <Button variant="link" onClick={handleNotMyDevice} inlineWrap>
              <Text size="body" color="info">
                This is not my device
              </Text>
            </Button>
          </div>
        )}

        <ReturnConfirmation />

        {error && (
          <div className="mt-12">
            <Text color="danger" size="body">
              {error}
            </Text>
          </div>
        )}

        <DeviceControlsNext isDisabled={isNextDisabled} isLoading={isLoading} onNext={handleNext} />
      </div>
    </SubmitContainer>
  )
}

export function DeviceControlsNext({
  onNext,
  isLoading,
  isDisabled,
}: {
  onNext: () => void
  isLoading: boolean
  isDisabled: boolean
}) {
  return (
    <div className="flex justify-end mt-20 gap-8">
      <Button variant="tertiary" onClick={SubmitRmaService.reset}>
        Back
      </Button>

      <Button variant="primary" onClick={onNext} disabled={isDisabled} loader={isLoading ? 'dots' : undefined}>
        Next
      </Button>
    </div>
  )
}
