import { Text } from '@shared-ui/components'
import { Button } from '@shared-ui/components/Button'
import { Container } from '@shared-ui/components/Container'
import { LoaderContainer } from '@shared-ui/components/Containers'
import { Grid } from '@shared-ui/components/Grid'
import { RemoteImage } from '@shared-ui/components/Image'
import { Device, Status, SupportBy, TicketIdRow, TicketRating, TimeElapsed } from '@shared-ui/components/TableColumns'
import { useNestedPath } from '@shared-ui/hooks'
import formatSerial from '@shared/lib/utils/format/serial'
import type { TicketStatus } from '@shared/tickets'
import type { TableColumnConfig, TableItem } from '@ubnt/ui-components'
import { Loader } from '@ubnt/ui-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { RMA_REDIRECT_EMPTY_DASHBOARD_TO_SUBMIT_KEY } from '../../constants'
import { TableWithStickyHeader } from './layout'
import { useOpenTicketsQuery } from './__generated__/Tickets'

interface TicketItem {
  id: string
  model: {
    name: string
    image?: string | null
  }
  macIdOrSerial: string
  createdAt: string
  status: TicketStatus
  supportBy: string
  showAlertIcon: boolean
  rating?: number
  isUnifiCare: boolean
}

// Friendly Robot Approves!
function useRmaEmptyTicketsRedirect(loading: boolean, tickets: readonly unknown[] | undefined) {
  const [redirectWasSet, setRedirectWasSet] = useState<boolean | undefined>(undefined)
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    if (redirectWasSet === undefined) {
      const keyVal = localStorage.getItem(RMA_REDIRECT_EMPTY_DASHBOARD_TO_SUBMIT_KEY)
      localStorage.removeItem(RMA_REDIRECT_EMPTY_DASHBOARD_TO_SUBMIT_KEY)
      setRedirectWasSet(!!keyVal)
    }
    if (loading) {
      return
    }
    if (redirectWasSet && (!tickets || tickets.length === 0)) {
      setRedirect(true)
    }
  }, [loading, tickets, setRedirect, redirectWasSet, setRedirectWasSet])

  return redirect
}

export const Tickets = () => {
  const nested = useNestedPath()
  const history = useHistory()

  const { loading, error, data, fetchMore } = useOpenTicketsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  const cursor = data?.tickets?.pageInfo.endCursor
  const hasMore = data?.tickets?.pageInfo.hasNextPage ?? true
  const tickets = data?.tickets?.result

  const redirectToSubmit = useRmaEmptyTicketsRedirect(loading, tickets)

  const items = useMemo(
    () =>
      (tickets || []).map(
        (ticket): TableItem<TicketItem> => ({
          id: ticket.id,
          model: {
            name: ticket.device.name,
            image: ticket.device.productImage,
          },
          macIdOrSerial: ticket.device.mac || '',
          createdAt: ticket.createdAt,
          status: ticket.status,
          supportBy: ticket.supportByName,
          rating: ticket.rating?.rating ?? undefined,
          showAlertIcon: false,
          isUnifiCare: ticket.device.isUnifiCare,
        }),
      ),
    [tickets],
  )

  const handleFetchMore = useCallback(async () => {
    if (!loading) {
      await fetchMore({ variables: { cursor } })
    }
  }, [loading, fetchMore, cursor])

  if (redirectToSubmit) {
    return <Redirect to="/submit" />
  }

  return (
    <StyledGrid $item $xs justify="center" $alignItems="center">
      {(() => {
        if (error || (loading && !data)) {
          return (
            <LoaderContainer>
              {error ? <Text size="inherit">Error loading data.</Text> : <Loader size="large" />}
            </LoaderContainer>
          )
        }

        return (
          <TableWithStickyHeader
            rowHeight={50}
            headerHeight={50}
            initialSortBy="createdAt"
            disableColumnFilters
            columns={columns}
            items={items}
            onRowClick={(item) => {
              history.push(nested(`/${item.id}`))
            }}
            renderPlaceholder={() => (
              <Container $padding={['m', 0]}>
                <Text size="inherit">No open tickets.</Text>
              </Container>
            )}
            renderFooter={() => {
              if (loading) {
                return (
                  <Container $padding={['m', 0]}>
                    <LoaderContainer>
                      <Loader />
                    </LoaderContainer>
                  </Container>
                )
              }

              if (!hasMore) {
                return null
              }

              return (
                <Container $padding={['m', 0]}>
                  <Button variant="secondary" onClick={handleFetchMore}>
                    Fetch more
                  </Button>
                </Container>
              )
            }}
          />
        )
      })()}
    </StyledGrid>
  )
}

const StyledGrid = styled(Grid)`
  padding: 0 5%;
`

const columns: TableColumnConfig<TicketItem>[] = [
  {
    id: 'id',
    label: <span data-testid="rma-dashboard-ticket-no">Ticket no.</span>,
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => <TicketIdRow ticketId={row.id} isUnifiCare={row.isUnifiCare} />,
  },
  {
    id: 'model',
    label: <span data-testid="rma-dashboard-device">Device</span>,
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => (
      <Device name={row.model.name} image={<RemoteImage url={row.model.image} width={24} height={24} />} />
    ),
  },
  {
    id: 'macIdOrSerial',
    label: <span data-testid="rma-dashboard-mac-id">MAC ID / Serial</span>,
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => formatSerial(row.macIdOrSerial),
  },
  {
    id: 'createdAt',
    label: <span data-testid="rma-dashboard-time-elapsed">Time elapsed</span>,
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => <TimeElapsed from={row.createdAt} />,
  },
  {
    id: 'status',
    label: <span data-testid="rma-dashboard-status">Status</span>,
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => <Status id={row.id} status={row.status} alertIcon={row.showAlertIcon} />,
  },
  {
    id: 'supportBy',
    label: <span data-testid="rma-dashboard-support-by">Support by</span>,
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => <SupportBy name={row.supportBy} />,
  },
  {
    id: 'rating',
    label: <span data-testid="rma-dashboard-rating">Rating</span>,
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => {
      return <TicketRating ticket={row} />
    },
  },
]
