import type { ShippingAddress } from '@shared/types/address'
import type { ShippingAddressId } from '@shared/types/brands'
import type { Device, KitReturn, KitReturnDevice } from '@shared/types/devices'
import create from 'zustand'
import type { CompanyAddress, SubmitRmaStep, SubmitSearchType } from './submit-rma-types'

type DeviceState = {
  kitReturn: KitReturn | null
}

type SubmitRmaState = {
  flow: SubmitRmaStep[]
  flowIndex: number
  totalSteps: number
  view: {
    curr: SubmitRmaStep
    prev: SubmitRmaStep
  }
  mac: string
  device: Device | null
  currSku: string
  seller: CompanyAddress | null
  shippingAddresses: ShippingAddress[] | null
  shippingAddressId: ShippingAddressId
  shippingAddress: ShippingAddress | null
  shippingAddressEdit: ShippingAddress | null
  shippingAddressNew: ShippingAddress | null
  shippingAddressValidated: ShippingAddress | null
  isOutOfWarranty: boolean
  isOriginalSeller: boolean
  isProofOfPurchaseNeeded: boolean
  isInvalidShippingAddress: boolean
  isInputMac: boolean
  submitAsReplacementPart: boolean
  searchType: SubmitSearchType | null
  deviceStateCurr: DeviceState
  deviceStatePrev: DeviceState | null
}

type SubmitRmaFunctions = {
  reset: () => void
  setFlow: (flow: SubmitRmaStep[]) => void
  resetFlow: () => void
  setFlowIndex: (flowIndex: number) => void
  setView: (view: SubmitRmaStep) => void
  replaceAddress: (prevAddressId: ShippingAddressId, addressNew: ShippingAddress) => void
  removeAddress: (addressId: ShippingAddressId) => void
  setKitReturn: (kitReturn: KitReturn | null) => void
  updateKitReturnDevice: (kitReturnDevice: KitReturnDevice) => void
}

const createInitialState = (): SubmitRmaState => {
  return {
    totalSteps: 0,
    flow: [],
    flowIndex: 0,
    view: {
      curr: 'searchHardware',
      prev: 'searchHardware',
    },
    mac: '',
    device: null,
    currSku: '',
    seller: null,
    shippingAddresses: null,
    shippingAddressId: 0,
    shippingAddress: null,
    shippingAddressEdit: null,
    shippingAddressNew: null,
    shippingAddressValidated: null,
    isOutOfWarranty: false,
    isOriginalSeller: true,
    isProofOfPurchaseNeeded: false,
    isInvalidShippingAddress: false,
    isInputMac: true,
    submitAsReplacementPart: false,
    searchType: null,
    deviceStateCurr: {
      kitReturn: null,
    },
    deviceStatePrev: null,
  }
}

export const useSubmitRmaStore = create<SubmitRmaState & SubmitRmaFunctions>((set) => ({
  ...createInitialState(),

  reset() {
    set(createInitialState())
  },

  setFlow(flow: SubmitRmaStep[]) {
    return set((state) => {
      return {
        flow,
        flowIndex: 0,
        view: { prev: state.view.curr, curr: flow[state.flowIndex] },
        totalSteps: flow.length,
      }
    })
  },

  resetFlow() {
    set({
      flow: [],
      flowIndex: 0,
      view: {
        curr: 'searchHardware',
        prev: 'searchHardware',
      },
      totalSteps: 0,
    })
  },

  setFlowIndex(flowIndex: number) {
    return set((state) => ({
      flowIndex,
      view: {
        prev: state.view.curr,
        curr: state.flow[flowIndex],
      },
    }))
  },

  setView(view: SubmitRmaStep) {
    return set((state) => ({
      view: {
        prev: state.view.curr,
        curr: view,
      },
    }))
  },

  replaceAddress(prevAddressId: ShippingAddressId, shippingAddressNew: ShippingAddress) {
    return set((state) => {
      if (!state.shippingAddresses) {
        return state
      }

      if (prevAddressId === -1) {
        return {
          shippingAddressNew,
        }
      }

      const index = state.shippingAddresses.findIndex((entry) => entry.id === prevAddressId)
      if (index === -1) {
        return state
      }

      const newAddresses = [...state.shippingAddresses]
      newAddresses[index] = shippingAddressNew

      return {
        shippingAddresses: newAddresses,
      }
    })
  },

  removeAddress(addressId: ShippingAddressId) {
    return set((state) => {
      if (!state.shippingAddresses) {
        return state
      }

      if (addressId === -1) {
        return {
          shippingAddressNew: null,
        }
      }

      const index = state.shippingAddresses.findIndex((entry) => entry.id === addressId)
      if (index === -1) {
        return state
      }

      const newAddresses = [...state.shippingAddresses]
      newAddresses.splice(index, 1)

      return {
        shippingAddresses: newAddresses,
      }
    })
  },

  setKitReturn(kitReturn: KitReturn | null) {
    return set((state) => {
      return {
        deviceStateCurr: {
          ...state.deviceStateCurr,
          kitReturn,
        },
      }
    })
  },

  updateKitReturnDevice(kitReturnDevice: KitReturnDevice) {
    return set((state) => {
      const { deviceStateCurr } = state

      const { kitReturn } = deviceStateCurr

      if (!kitReturn || kitReturn.type !== 'ua-g2') {
        return state
      }

      const newDevices = [...kitReturn.devices]
      newDevices[kitReturnDevice.index] = kitReturnDevice

      return {
        deviceStateCurr: {
          ...state.deviceStateCurr,
          kitReturn: {
            ...kitReturn,
            devices: newDevices,
          },
        },
      }
    })
  },
}))
