import { Button, Buttons, FileAttachments, Spacer } from '@shared-ui/components'
import useNavigate from '@shared-ui/hooks/useNavigate'
import type { DeviceId } from '@shared/types/brands'
import { Text } from '@ubnt/ui-components'
import { useEffect } from 'react'
import { useSubmitRma } from '../../context/SubmitRma'
import { SubmitRmaService } from '../../submit-rma-service'
import { useSubmitRmaStore } from '../../submit-rma-store'
import { RmaDevicePanelSmall } from '../rma-device-panel-small'
import { SellerInfo } from '../seller-info'
import { StepHeading } from '../step-heading'
import { SubmitContainer } from '../submit-container'
import { UCareGradient } from '../u-care'
import { UCareBadge } from '../u-care-badge'
import { useConfirmInformation_CreateTicketMutation as useCreateTicketMutation } from './__generated__/ConfirmInformation'

export function ConfirmInformation() {
  const { device } = useSubmitRmaStore()

  const isUCare = !!device?.isUnifiCare

  return (
    <SubmitContainer>
      {isUCare && (
        <>
          <UCareGradient />
          <UCareBadge />
        </>
      )}

      <div className="flex column p-16 mt-6 gap-24 relative">
        <StepHeading heading="Let's review" />

        <Content />

        <Footer />
      </div>
    </SubmitContainer>
  )
}

function Content() {
  const { device, seller, currSku, shippingAddress, shippingAddressValidated } = useSubmitRmaStore()
  const { failureCategory, problemDescription, otherAttachmentFiles, proofOfPurchaseFiles } = useSubmitRma()

  const shipToAddress = shippingAddressValidated || shippingAddress

  return (
    <>
      <div className="flex column">
        <Text size="body" color="secondary" weight="bold" className="mb-8">
          Product & Issue
        </Text>
        <RmaDevicePanelSmall device={device!} skuOverride={currSku} />
      </div>

      <div className="flex column">
        <Text size="body" color="secondary" className="mb-4">
          {failureCategory?.name || failureCategory?.id || 'Missing failure category.'}
        </Text>
        <Text size="body" color="tertiary">
          {problemDescription}
        </Text>
        <FileAttachments files={otherAttachmentFiles} />
      </div>

      {!!proofOfPurchaseFiles.length && (
        <div className="flex column">
          <Text size="body" color="primary" weight="bold">
            Proof of Purchase
          </Text>
          <FileAttachments files={proofOfPurchaseFiles} />
        </div>
      )}

      {seller && (
        <div className="flex column">
          <Text size="body" color="primary" weight="bold" className="mb-8">
            Vendor
          </Text>

          <SellerInfo seller={seller} />
        </div>
      )}

      {shipToAddress && (
        <div className="flex column">
          <Text size="body" color="primary" weight="bold" className="mb-8">
            Shipping to
          </Text>

          <SellerInfo seller={shipToAddress} />
        </div>
      )}
    </>
  )
}

function Footer() {
  const { device, isProofOfPurchaseNeeded, shippingAddressId, seller, shippingAddress } = useSubmitRmaStore()
  const {
    userCompanyId,
    companyId,
    failureCategory,
    problemDescription,
    proofOfPurchaseFiles,
    otherAttachmentFiles,
  } = useSubmitRma()

  const [createTicket, { loading, error, data }] = useCreateTicketMutation()
  const ticketId = data?.createTicket.ticket?.id
  const navigate = useNavigate()

  useEffect(() => {
    if (!ticketId) return

    SubmitRmaService.reset()

    navigate('/dashboard/tickets', { sidebarParam: ticketId })
  }, [ticketId, navigate])

  const submitRma = async () => {
    if (!seller) {
      return
    }

    const isNewSeller = seller.id === ''

    try {
      await createTicket({
        variables: {
          input: {
            deviceId: device?.id as DeviceId,
            reseller: isNewSeller
              ? { name: seller.name, suggestedAddress: seller.address1, website: seller.website }
              : null,
            companyId: isNewSeller ? null : seller.id,
            failureCategoryId: failureCategory?.id || '',
            description: problemDescription || '',
            proofOfPurchase: proofOfPurchaseFiles?.map(({ file }) => file),
            otherFiles: otherAttachmentFiles?.map(({ file }) => file),
            shippingAddress:
              shippingAddressId === -1
                ? {
                    address1: shippingAddress?.address1 ?? '',
                    city: shippingAddress?.city ?? '',
                    companyName: shippingAddress?.companyName ?? '',
                    country: shippingAddress?.country ?? '',
                    firstName: shippingAddress?.firstName ?? '',
                    lastName: shippingAddress?.lastName ?? '',
                    phoneNumber: shippingAddress?.phoneNumber ?? '',
                    state: shippingAddress?.state ?? '',
                    zipcode: shippingAddress?.zipcode ?? '',
                  }
                : null,
            shippingAddressId,
          },
        },
      })
    } catch {
      //
    }
  }

  const handleNext = () => {
    void submitRma()
  }

  const errors: string[] = []
  if (!device?.id) errors.push('Missing device.')
  if (!seller && !userCompanyId && !companyId) errors.push('Missing company.')
  if (!failureCategory?.id) errors.push('Missing failure category.')
  if (!problemDescription) errors.push('Missing problem description.')
  if (isProofOfPurchaseNeeded && !proofOfPurchaseFiles?.length) errors.push('Missing proof of purchase.')
  if (!shippingAddress && !shippingAddressId) errors.push('Missing address.')
  if (shippingAddress && (!shippingAddress.address1 || !shippingAddress.zipcode)) {
    errors.push('Your address is incomplete, please update it.')
  }

  return (
    <>
      {[...errors, error?.message].filter(Boolean).map((err) => (
        <>
          <Text color="danger">{err}</Text>
          <Spacer />
        </>
      ))}

      <Buttons>
        <Button
          variant="tertiary"
          disabled={loading || !!ticketId}
          onClick={SubmitRmaService.back}
          data-testid="submit-rma-back"
          $minWidth="0px"
        >
          Back
        </Button>

        <Button
          variant="primary"
          disabled={!!errors.length || loading}
          loader={loading ? 'dots' : undefined}
          onClick={handleNext}
          data-testid="submit-rma-next"
          $minWidth="0px"
        >
          Submit Request
        </Button>
      </Buttons>
    </>
  )
}
