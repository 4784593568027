type ReplacementDevice = {
  name: string
  isHandledByUbiquiti: boolean
  isUnifiCare: boolean
}

export const getReplacementPartSku = (device: ReplacementDevice): string | null => {
  if (!device.isHandledByUbiquiti || device.isUnifiCare) {
    return null
  }

  const sku = device.name.toLowerCase()

  const supportsPartReplacement =
    sku.indexOf('unvr') === 0 ||
    sku.indexOf('udm-pro') === 0 ||
    sku.indexOf('udm-se') === 0 ||
    sku.indexOf('unvr-pro') == 0

  if (supportsPartReplacement) {
    return 'SP-HDD-Tray'
  }

  return null
}
