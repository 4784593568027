import { ProductsDropdown } from '@shared-ui/components/products-dropdown'
import { useRequest } from '@shared-ui/hooks'
import { Modal, Text } from '@ubnt/ui-components'
import type { NetsuiteProductId } from 'rma-shared/types/brands'
import { SubmitRmaService } from '../submit-rma-service'
import { useSubmitRmaStore } from '../submit-rma-store'
import { ReturnConfirmation } from './kits/return-confirmation'

export const MacNotFoundModal = ({ onClose }: { onClose: () => void }) => {
  const { device, mac, deviceStateCurr } = useSubmitRmaStore()

  const [createDevice, { loading: createDeviceFromLoading, error }] = useRequest('createDevice')
  const [updateDeviceKit, { loading: updateDeviceKitLoading }] = useRequest('updateDeviceKit')

  const isLoading = createDeviceFromLoading || updateDeviceKitLoading
  const isNextDisabled = isLoading || SubmitRmaService.canStartFlow()

  const handleProductSelected = async (productId: NetsuiteProductId) => {
    try {
      const response = await createDevice({
        mac,
        productId,
        productName: '',
      })

      SubmitRmaService.processDevice(response.device)
    } catch (err) {
      //
    }
  }

  const handleNext = async () => {
    if (!device) {
      return
    }

    if (deviceStateCurr.kitReturn) {
      try {
        const result = await updateDeviceKit({ deviceId: device.id, kitReturn: deviceStateCurr.kitReturn })
        SubmitRmaService.processDevice(result.device)
        SubmitRmaService.startFlow()
      } catch (err) {
        //
      }
    } else {
      SubmitRmaService.startFlow()
    }
  }

  return (
    <Modal
      isOpen
      title="MAC / Serial Number Not Found"
      size="small"
      height="small"
      onRequestClose={onClose}
      actions={[
        {
          text: 'Cancel',
          variant: 'tertiary',
          onClick: onClose,
        },
        {
          text: 'Next',
          variant: 'primary',
          loader: isLoading ? 'dots' : undefined,
          disabled: isNextDisabled,
          onClick: () => void handleNext(),
        },
      ]}
    >
      <Text size="body" className="mb-24">
        We couldn’t find your device in our database. Please select your product below.
      </Text>

      <ProductsDropdown
        label="Select product"
        variant="secondary"
        onChange={(option) => {
          void handleProductSelected(option.value)
        }}
      />

      <ReturnConfirmation />

      {error && (
        <Text color="danger" size="body">
          {error}
        </Text>
      )}
    </Modal>
  )
}
