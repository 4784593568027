import type { DropdownOption, DropdownRef } from '@ubnt/ui-components'
import { Dropdown, Text } from '@ubnt/ui-components'
import { useEffect, useRef, useState } from 'react'
import type { CompanyId } from 'rma-shared/types/brands'
import { upperFirst } from 'lodash'
import styled from 'styled-components'
import { useRequest } from '../hooks'

type SearchCompanyDropdownProps = {
  label?: string
  placeholder?: string
  value?: CompanyId
  exceptionValue?: CompanyId
  onChange: (companyId: CompanyId) => void
}

export const SearchCompanyDropdown = ({
  label = 'Company',
  placeholder = 'Search a company by name',
  value,
  exceptionValue,
  onChange,
}: SearchCompanyDropdownProps) => {
  const dropdownEl = useRef<DropdownRef | null>(null)
  const [searchCompany, { data, loading }] = useRequest('companySearch')

  const [search, setSearch] = useState('')
  const [options, setOptions] = useState<DropdownOption[]>([])

  async function handleSearchCompany(query: string) {
    setOptions([])

    try {
      const result = await searchCompany({ query })

      const resultCompanies = exceptionValue
        ? result.companies.filter((entry) => entry.id !== exceptionValue)
        : result.companies
      const newOptions = resultCompanies.map((entry) => ({
        label: entry.name,
        value: entry.id,
        additionalInfo: (
          <StatusText>
            {upperFirst(entry.entityType)} ({entry.status})
          </StatusText>
        ),
      }))

      setOptions(newOptions)
    } catch (err) {
      //
    }
  }

  useEffect(() => {
    if (!search) {
      return
    }

    void handleSearchCompany(search)
  }, [search])

  let emptyMessage = ''
  if (loading) {
    emptyMessage = 'Loading...'
  } else if (search && !data?.companies.length) {
    emptyMessage = 'No results found.'
  } else {
    emptyMessage = placeholder
  }

  return (
    <StyledDropdown
      label={label}
      searchable
      clearable
      portal
      searchEmptyMessage={emptyMessage}
      width="100%"
      placeholder="Search"
      fontSize="body"
      value={value || ''}
      ref={dropdownEl}
      options={options}
      onChange={(_, id) => {
        onChange(id as CompanyId)
        dropdownEl.current?.blur()
      }}
      onInputChange={(_, newValue) => {
        setSearch(newValue as string)
      }}
    />
  )
}

const StatusText = styled(Text).attrs({ color: 'tertiary' })`
  text-align: right;
  white-space: nowrap;
`

const StyledDropdown = styled(Dropdown)`
  input + div ${StatusText} {
    display: none;
  }
` as typeof Dropdown
