import type { FileUploadFile } from '@shared-ui/components'
import type { WarrantyStatus } from '@shared-ui/generated/graphql'
import type { ResellerCreate, ShippingAddressInput } from '@shared/lib/validation/schemas'
import type { CompanyId, DeviceId } from '@shared/types/brands'
import type { FailureCategory } from '@shared/types/tickets'
import create from 'zustand'
import type { SubmitRmaStep } from '../submit-rma-types'

export type Device = {
  id: DeviceId
  isUnifiCare?: boolean
  warrantyStatus?: WarrantyStatus
  isHandledByUbiquiti?: boolean
  isSellerInactive?: boolean
}

export type ContextDevice = Device | null | undefined

export type ShippingAddress = ShippingAddressInput
export type Reseller = ResellerCreate

const Steps: SubmitRmaStep[] = ['selectSeller', 'describeIssue', 'confirmInformation']

export type RebootIssue = 'poe-switch' | 'poe-adapter'

export const totalStepCount = Steps.length

interface State {
  companyId?: CompanyId | null
  userCompanyId?: CompanyId
  failureCategory?: FailureCategory
  rebootIssue?: RebootIssue
  problemDescription?: string
  proofOfPurchaseFiles: FileUploadFile[]
  proofOfPurchaseFilesPrev: FileUploadFile[]
  otherAttachmentFiles: FileUploadFile[]
  otherAttachmentFilesPrev: FileUploadFile[]
  totalSteps?: number
}

interface StateFunctions {
  resetContext: () => void
  setCompanyId: (companyId?: CompanyId | null) => void
  setUserCompanyId: (userCompanyId?: CompanyId) => void
  setFailureCategory: (failureCategory?: FailureCategory) => void
  setRebootIssue: (rebootIssue: RebootIssue) => void
  setProblemDescription: (problemDescription?: string) => void
  setProofOfPurchaseFiles: (proofOfPurchaseFiles: FileUploadFile[]) => void
  setOtherAttachmentFiles: (otherAttachmentFiles: FileUploadFile[] | undefined) => void
}

const initialState: State = {
  companyId: undefined,
  userCompanyId: undefined,
  failureCategory: undefined,
  rebootIssue: undefined,
  problemDescription: undefined,
  proofOfPurchaseFiles: [],
  proofOfPurchaseFilesPrev: [],
  otherAttachmentFiles: [],
  otherAttachmentFilesPrev: [],
  totalSteps: undefined,
}

export const useSubmitRma = create<State & StateFunctions>((set) => ({
  ...initialState,
  resetContext: () => set({ ...initialState }),
  setCompanyId: (companyId) => set({ companyId }),
  setUserCompanyId: (userCompanyId) => set({ userCompanyId }),
  setFailureCategory: (failureCategory) => set({ failureCategory }),
  setRebootIssue: (rebootIssue) => set({ rebootIssue }),
  setProblemDescription: (problemDescription) => set({ problemDescription }),
  setProofOfPurchaseFiles: (proofOfPurchaseFiles) => set({ proofOfPurchaseFiles }),
  setOtherAttachmentFiles: (otherAttachmentFiles) => set({ otherAttachmentFiles }),
}))
