import {
  TICKET_ATTACHMENT_MAX_FILE_SIZE,
  TICKET_ATTACHMENT_VALID_FILE_TYPES,
  TICKET_ATTACHMENT_VALID_IMAGE_FILE_TYPES,
} from '@shared/tickets/constants'
import { AttachmentIcon, FileIcon, TrashIcon } from '@ubnt/icons'
import { Button, cssVariables } from '@ubnt/ui-components'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Text } from './Text'
import { CustomLabel } from './custom-label'

type UploadFileType = 'default' | 'images'

export type FileUploadFile = {
  file: File
  error: string | null
}

export function FileUpload({
  fileType,
  files,
  setFiles,
  label,
  required,
  description,
  maxFiles = 1,
}: {
  fileType?: UploadFileType
  files: FileUploadFile[]
  setFiles: (files: FileUploadFile[]) => void
  maxFiles?: number
  label?: string
  required?: boolean
  description?: string
}) {
  const fileUploadRef = useRef<HTMLInputElement>(null)
  const [genericError, setGenericError] = useState('')

  const handleUpload = useCallback(() => {
    if (fileUploadRef.current) {
      fileUploadRef.current.click()
    }
  }, [fileUploadRef])

  const handleRemove = (index: number) => {
    files.splice(index, 1)
    setFiles([...files])
    setGenericError('')
  }

  const acceptFileTypes =
    fileType && fileType === 'images' ? TICKET_ATTACHMENT_VALID_IMAGE_FILE_TYPES : TICKET_ATTACHMENT_VALID_FILE_TYPES

  return (
    <div className="flex column">
      {label && <CustomLabel label={label} extra={required ? '(required)' : '(optional)'} />}

      {description && (
        <Text size="body" className="mb-8">
          {description}
        </Text>
      )}

      <div className="flex column mb-4">
        <UploadedAttachments files={files} onRemove={handleRemove} />
      </div>

      <input
        type="file"
        className="hide"
        accept={acceptFileTypes.join(',')}
        ref={fileUploadRef}
        multiple
        onChange={(e) => {
          if (!e.currentTarget.files) {
            return
          }

          const attachedFiles: FileUploadFile[] = [...e.currentTarget.files].map((file: File) => {
            return {
              file,
              error: null,
            }
          })

          if (!attachedFiles.length) {
            return
          }

          const newFiles = [...files, ...attachedFiles]
          if (newFiles.length > maxFiles) {
            newFiles.length = maxFiles
            setGenericError('You have attached a maximum number of files')
          } else {
            setGenericError('')
          }

          for (const attachedFile of attachedFiles) {
            if (attachedFile.file.size > TICKET_ATTACHMENT_MAX_FILE_SIZE) {
              attachedFile.error = 'The file size exceeds the limit allowed'
            }
          }

          setFiles(newFiles)
          e.currentTarget.value = ''
        }}
      />

      <div className="flex align-center">
        <Button
          className="width-142px height-32px bg-gray color-blue"
          variant="tertiary"
          size="small"
          Icon={AttachmentIcon}
          onClick={handleUpload}
          title="Upload files"
        >
          Attach File
        </Button>
        <Text size="caption" color="tertiary" className="ml-8">
          {files.length || 0}/{maxFiles} files
        </Text>
      </div>

      {genericError && (
        <div className="mt-12">
          <Text size="body" color="danger">
            {genericError}
          </Text>
        </div>
      )}
    </div>
  )
}

function UploadedAttachment({ uploadedFile, onRemove }: { uploadedFile: FileUploadFile; onRemove(): void }) {
  return (
    <>
      <div className="flex justify-center align-center height-32px px-4 py-6 bg-gray border-radius-4">
        <FileIcon variant="fill" color={cssVariables.motifs.light.neutral08} />
        <Text truncate className="ml-4">
          {uploadedFile.file.name}
        </Text>

        <Button
          Icon={TrashIcon}
          onClick={onRemove}
          variant="tertiary"
          size="small"
          style={{ padding: `0 ${cssVariables['spacing-xs']}`, height: 24 }}
        />
      </div>

      {uploadedFile.error && <Text color="danger">{uploadedFile.error}</Text>}
    </>
  )
}

export function UploadedAttachments({ files, onRemove }: { files: FileUploadFile[]; onRemove(index: number): void }) {
  if (!files.length) {
    return null
  }

  return (
    <div className="flex column gap-4">
      {files.map((entry, index) => {
        return <UploadedAttachment uploadedFile={entry} onRemove={() => onRemove(index)} key={entry.file.name} />
      })}
    </div>
  )
}

function FileAttachment({ file }: { file: File }) {
  const objectURL = useMemo(() => {
    return URL.createObjectURL(file)
  }, [file])

  return (
    <div className="flex justify-center align-center height-32px px-4 py-6 bg-gray border-radius-4">
      <FileIcon variant="fill" color={cssVariables.motifs.light.neutral08} />
      <Text truncate className="ml-4">
        {file.name}
      </Text>
      <Button component="a" variant="link" href={objectURL} target="_blank" rel="noreferrer">
        View
      </Button>
    </div>
  )
}

export function FileAttachments({ files }: { files: FileUploadFile[] }) {
  if (!files.length) {
    return null
  }

  return (
    <div className="flex column gap-4">
      {files.map((entry) => {
        return <FileAttachment file={entry.file} key={entry.file.name} />
      })}
    </div>
  )
}
