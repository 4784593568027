import { WarrantyStatus } from '@shared-ui/generated/graphql'
import { isProofOfPurchaseRequired } from '@shared/lib/utils/checks/is-proof-of-purchase-required'
import type { Company } from '@shared/types/company'
import type { Device } from '@shared/types/devices'
import { KitReturnService } from './kit-return-service'
import { useSubmitRmaStore } from './submit-rma-store'
import type { SubmitRmaStep } from './submit-rma-types'
import { SubmitRmaFlow } from './submit-rma-types'

export const SubmitRmaService = {
  reset: () => {
    useSubmitRmaStore.getState().reset()
  },

  setView: (view: SubmitRmaStep) => {
    useSubmitRmaStore.getState().setView(view)
  },

  startFlow: () => {
    const { device, isProofOfPurchaseNeeded } = useSubmitRmaStore.getState()

    if (!device) {
      return
    }

    const isWithoutSellerFlow =
      device.isHandledByUbiquiti &&
      !isProofOfPurchaseNeeded &&
      !device.isSellerInactive &&
      device.warrantyStatus === WarrantyStatus.Active

    useSubmitRmaStore.getState().setFlow(isWithoutSellerFlow ? SubmitRmaFlow.withoutSeller : SubmitRmaFlow.default)
  },

  next: () => {
    const { flow, flowIndex, device } = useSubmitRmaStore.getState()

    if (!device) {
      return
    }

    const nextFlowIndex = flowIndex + 1
    const nextStep = nextFlowIndex < flow.length ? flow[nextFlowIndex] : null
    if (!nextStep) {
      return
    }

    useSubmitRmaStore.getState().setFlowIndex(nextFlowIndex)

    SubmitRmaService.setInvalidShippingAddress(false)
  },

  back: () => {
    const { flow, flowIndex, device } = useSubmitRmaStore.getState()

    if (!device) {
      return
    }

    const prevFlowIndex = flowIndex - 1
    const prevStep = prevFlowIndex >= 0 ? flow[prevFlowIndex] : null
    if (!prevStep) {
      SubmitRmaService.reset()
      return
    }

    useSubmitRmaStore.getState().setFlowIndex(prevFlowIndex)
  },

  backToFlow: () => {
    const { flowIndex } = useSubmitRmaStore.getState()

    useSubmitRmaStore.getState().setFlowIndex(flowIndex)
  },

  setMac: (mac: string) => {
    useSubmitRmaStore.setState({ mac, searchType: null, device: null, isOutOfWarranty: false })
  },

  processDevice(device: Device | null) {
    SubmitRmaService.setDevice(device)

    const kitReturn = KitReturnService.checkForKitReturn(device)
    useSubmitRmaStore.getState().setKitReturn(kitReturn)

    return !!kitReturn
  },

  setDevice(device: Device | null) {
    const isProofOfPurchaseNeeded = device ? isProofOfPurchaseRequired(device) : false

    useSubmitRmaStore.setState({
      device,
      isProofOfPurchaseNeeded,
    })
  },

  setSeller: (seller: Company | null, isOriginalSeller: boolean) => {
    useSubmitRmaStore.setState({ seller, isOriginalSeller })
  },

  updateSeller: (seller: Company | null) => {
    const { isOriginalSeller } = useSubmitRmaStore.getState()

    useSubmitRmaStore.setState({
      seller,
      isOriginalSeller,
    })
  },

  setInvalidShippingAddress: (isInvalidShippingAddress: boolean) => {
    useSubmitRmaStore.setState({
      isInvalidShippingAddress,
    })
  },

  isExternalAddressValidation() {
    const { shippingAddress, device } = useSubmitRmaStore.getState()

    return device?.isHandledByUbiquiti && device?.isAdvancedFlowAvailable && shippingAddress?.country === 'US'
  },

  canStartFlow() {
    return KitReturnService.canStartFlow()
  },

  getKitReturnMac(macIndex: number) {
    const { deviceStateCurr } = useSubmitRmaStore.getState()

    if (!deviceStateCurr.kitReturn || deviceStateCurr.kitReturn.type !== 'ua-g2') {
      return ''
    }

    const mac = deviceStateCurr.kitReturn.devices[macIndex]?.mac || ''

    return mac
  },
}
