import { TicketStatus, TICKET_STATUS_LABELS } from '@shared/tickets'
import { Text, Tooltip } from '@ubnt/ui-components'
import type { ReactNode } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import type { ShipmentUId, TicketId } from 'rma-shared/types/brands'
import styled from 'styled-components'
import { useNavigate, useNestedPath } from '../hooks'
import { formatDate, timeElapsedFrom } from '../utils/time'
import { UniFiCareSmall } from './Device'
import { AlertIcon } from './icons/AltertIcon'
import { Rating } from './Rating'
import { Row, Text18 } from './SprintShared'

export const Device = ({ name, image }: { name: string; image: ReactNode }) => {
  return (
    <Row $centerV>
      <div style={{ width: '24px' }}>{image}</div>
      <Text size="body" title={name}>
        {name}
      </Text>
    </Row>
  )
}

interface TimeElapsedProps {
  from: string
  to?: string
}

export const TimeElapsed = ({ from, to }: TimeElapsedProps) => {
  const [elapsed, setElapsed] = useState(timeElapsedFrom(from, to || new Date().toISOString()))

  useEffect(() => {
    if (to) {
      return () => undefined
    }

    const timer = setInterval(() => {
      setElapsed(timeElapsedFrom(from, to || new Date().toISOString()))
    }, 60000)
    return () => {
      clearInterval(timer)
    }
  }, [setElapsed, from, to])

  return (
    <Text18 ellipsis full title={elapsed}>
      {elapsed}
    </Text18>
  )
}

export const TimeLeft = ({ from = new Date().toISOString(), to }: { from?: string; to?: string | null }) => (
  <Text18>{to ? timeElapsedFrom(from, to) : ''}</Text18>
)

export const FormattedDate = ({ date }: { date?: string | null }) => <Text18>{date ? formatDate(date) : ''}</Text18>

export const TicketIdRow = ({ ticketId, isUnifiCare }: { ticketId: TicketId; isUnifiCare?: boolean }) => {
  return (
    <Row>
      <Text18>{ticketId}</Text18>

      {isUnifiCare && (
        <Tooltip message="Covered by UI Care" portal position="bottom">
          <UniFiCareSmall />
        </Tooltip>
      )}
    </Row>
  )
}

export const ViewRow = ({ id, text }: { id: string | number; text: string }) => {
  const nested = useNestedPath()

  return <Link to={nested(`/${id}`)}>{text}</Link>
}

export const SupportBy = ({ name }: { name: string }) => {
  return (
    <Text18 truncate title={name}>
      {name}
    </Text18>
  )
}

export const Customer = ({ id, name, link = true }: { id: string; name: string; link?: boolean }) => {
  const nested = useNestedPath()

  if (!link) {
    return <Text18 truncate>{name}</Text18>
  }

  return (
    <LinkStyled to={nested(`/${id}/customer`)} title={name}>
      {name}
    </LinkStyled>
  )
}

const ActionableStatuses = [
  TicketStatus.Submitted,
  TicketStatus.DistributorIdentified,
  TicketStatus.Accepted,
  TicketStatus.Processing,
  TicketStatus.InFulfilment,
]

interface StatusProps {
  id: TicketId
  status: TicketStatus
  link?: boolean
  alertIcon?: boolean
}

export const Status = ({ id, status, link, alertIcon }: StatusProps) => {
  const nested = useNestedPath()
  const label = TICKET_STATUS_LABELS[status]

  if (!link || !ActionableStatuses.includes(status)) {
    return (
      <>
        {alertIcon && (
          <span title="Awaiting Item / Tracking Number">
            <AlertIcon style={{ marginRight: '5px' }} />
          </span>
        )}

        <Text18 ellipsis title={label}>
          {label}
        </Text18>
      </>
    )
  }

  return (
    <Text18 ellipsis title={label}>
      <Link to={nested(`/${id}/manage`)}>{label}</Link>
    </Text18>
  )
}

interface TrackingProps {
  ticketId: TicketId
  trackingNumber: string
}

export const Tracking = ({ ticketId, trackingNumber }: TrackingProps) => {
  return (
    <Text18 truncate title={trackingNumber}>
      {trackingNumber}
    </Text18>
  )
}

export function Shipment({ shipmentUId }: { shipmentUId: ShipmentUId | null }) {
  if (!shipmentUId) {
    return null
  }

  return (
    <Text18
      truncate
      title={shipmentUId}
      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        event.stopPropagation()
      }}
    >
      <Link to={`/shipments/${shipmentUId}`} target="_blank" rel="noopener noreferrer">
        {shipmentUId}
      </Link>
    </Text18>
  )
}

export function TicketRating({ ticket }: { ticket: { id: string; rating?: number } }) {
  const navigate = useNavigate()
  const nested = useNestedPath()
  const onChange = useCallback(
    (rating: number | null) => {
      navigate(nested(`/${ticket.id}/rate`), { rating })
    },
    [ticket.id, navigate, nested],
  )

  return (
    <Rating
      key={ticket.id}
      value={ticket.rating}
      onChange={onChange}
      width="112px"
      placeholder={!ticket.rating ? <RateText>Rate your experience</RateText> : undefined}
      height="32px"
    />
  )
}

const RateText = styled(Text18)`
  color: ${(props) => props.theme.colors.link};
`

export const LinkStyled = styled(Link)`
  /* https://github.com/ubiquiti/ui-components/blob/f0ba2a7247a5502f40164b4e99b882f9c4f6916a/src/Text/Text.module.css#L109 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
