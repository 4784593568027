import {
  isLetters,
  isNumbers,
  isValidDate,
  isValidFullYear,
  isValidMonth,
  isValidNewCMCode,
  isValidOldCMCode,
  isValidShortYear,
  isValidWeek,
  startWithAnyOf,
} from './helpers'

export const MAC_LENGTH = 12
export const MAC_SPECIAL_STRING_LENGTH = 6

const MAC_SPECIAL_STRINGS = [
  '00156d',
  '002722',
  '0418d6',
  '18e829',
  '245a4c',
  '24a43c',
  '44d9e7',
  '687251',
  '68d79a',
  '7483c2',
  '74acb9',
  '784558',
  '788a20',
  '802aa8',
  'b4fbe4',
  'd021f9',
  'dc9fdb',
  'e063da',
  'f09fc2',
  'f492bf',
  'fcecda',
  '245a4c',
  '602232',
  '70a741',
  '784558',
  '942a6f',
  'ac8ba9',
  'd021f9',
  'e43883',
  'f4e2c6',
  '28704e',
  '9c05d6',
  'd8b370',
]

// Remove all '-' and spaces from string and convert to lowercase
export const parseMacOrSerialInput = (input: string) => input.replace(/[-:|\s]/g, '').toLowerCase()

export const isValidMac = (input: string) => {
  const parsed = parseMacOrSerialInput(input)

  if (parsed.length === MAC_LENGTH && isProbablyMac(parsed)) {
    return true
  }

  return false
}

export const hasMacSupportedCharacters = (input: string) => {
  if (!input) {
    return true
  }

  const matching = input.match(/[0-9a-fA-F]/g)

  return matching && matching.length === input.length
}

export const isProbablyMac = (input: string) => {
  const parsed = parseMacOrSerialInput(input)

  if (!hasMacSupportedCharacters(parsed)) {
    return false
  }

  if (startWithAnyOf(MAC_SPECIAL_STRINGS, parsed)) {
    return true
  }

  return false
}

export const validateMacSerial = (input: string) => {
  if (typeof input !== 'string' || !input) {
    return false
  }

  const inputToValidate = parseMacOrSerialInput(input)

  if (inputToValidate.length === 7) {
    // 1. Starts with "kpc2h"
    if (inputToValidate.startsWith('kpc2h')) {
      return true
    }
  }

  if (inputToValidate.length === 8) {
    const fullYear = inputToValidate.substring(0, 4)
    const month = inputToValidate.substring(4, 6)
    const date = inputToValidate.substring(6, 8)

    // 1. Char 1-4 is valid long year and char 5-6 and char 7-8 contains valid month and date
    if (isValidFullYear(fullYear) && isValidMonth(month) && isValidDate(fullYear, month, date)) {
      return true
    }

    // 2. Starts with "VD"
    if (inputToValidate.startsWith('vd')) {
      return true
    }
  }

  if (inputToValidate.length === 9) {
    const shortYear = inputToValidate.substring(1, 3)
    const month = inputToValidate.substring(3, 5)

    // 1. Char 2-3 and char 4-5 contains valid year and month
    if (isValidShortYear(shortYear) && isValidMonth(month)) {
      return true
    }

    // 2. Char 1-2 are letters, char 3 is number and char 4 is letter
    if (
      isLetters(inputToValidate.substring(0, 2)) &&
      isNumbers(inputToValidate.substring(2, 3)) &&
      isLetters(inputToValidate.substring(3, 4))
    ) {
      return true
    }
  }

  if (inputToValidate.length === 11) {
    const specialStrings = ['1809411', '1809412']

    const shortYear = inputToValidate.substring(0, 2)
    const monthOrWeek = inputToValidate.substring(2, 4)

    // 1. Starts with "1809411" or "1809412"
    if (startWithAnyOf(specialStrings, inputToValidate)) {
      return true
    }

    // 2. Char 1-2 and char 3-4 contains valid year and month
    if (isValidShortYear(shortYear) && isValidMonth(monthOrWeek)) {
      return true
    }

    // 3. Char 1-2 and char 3-4 contains valid year and week and last 6 chars are digits
    if (
      isValidShortYear(shortYear) &&
      isValidWeek(monthOrWeek) &&
      isNumbers(inputToValidate.substring(inputToValidate.length - 6, inputToValidate.length))
    ) {
      return true
    }
  }

  if (inputToValidate.length === 12) {
    // 1. Validate if input is a valid MAC.
    if (isValidMac(input)) {
      return true
    }

    // 2. Starts with "k" and char 2-3 and char 4-5 contains valid year and month
    if (
      inputToValidate.startsWith('k') &&
      isValidShortYear(inputToValidate.substring(1, 3)) &&
      isValidMonth(inputToValidate.substring(3, 5))
    ) {
      return true
    }

    // 3. Char 1-2 are valid letters and chars 3-4 and 5-6 contains valid year and month
    if (
      isLetters(inputToValidate.substring(0, 2)) &&
      isValidShortYear(inputToValidate.substring(2, 4)) &&
      isValidMonth(inputToValidate.substring(4, 6))
    ) {
      return true
    }

    // 4. Starts with "cz" or "no"
    if (startWithAnyOf(['cz', 'no'], inputToValidate)) {
      return true
    }

    // 5. Starts with old CM code and chars 2-3 and chars 4-5 contains valid year and month
    if (
      isValidOldCMCode(inputToValidate.substring(0, 1)) &&
      isValidShortYear(inputToValidate.substring(1, 3)) &&
      isValidMonth(inputToValidate.substring(3, 5))
    ) {
      return true
    }
  }

  if (inputToValidate.length === 13) {
    // 1. Starts with "smwa" or "smca"
    if (startWithAnyOf(['smwa', 'smca'], inputToValidate)) {
      return true
    }

    // 2. Starts with new CM code and chars 3-4 and chars 5-6 contains valid year and month
    if (
      isValidNewCMCode(inputToValidate.substring(0, 2)) &&
      isValidShortYear(inputToValidate.substring(2, 4)) &&
      isValidMonth(inputToValidate.substring(4, 6))
    ) {
      return true
    }
  }

  return false
}

export const beautifyMac = (mac?: string | null) => {
  if (!mac) {
    return ''
  }

  return (parseMacOrSerialInput(mac).match(/.{1,2}/g) ?? []).join(':').toUpperCase()
}
