import type { DeviceId, NetsuiteProductId } from './brands'
import type { Company } from './company'
import type { WarrantyStatus } from './enums'
import type { ProductParentCategory } from './products'

export type DeviceEntry = {
  id: DeviceId
  mac: string
  sku: string
  productId: NetsuiteProductId
}

export type Device = {
  id: DeviceId
  mac: string | null
  dateCode: string | null
  name: string
  sellerId: string | null
  coreItemCode: string | null
  warrantyStatus: WarrantyStatus
  isUnifiCare: boolean
  isHandledByUbiquiti: boolean
  isSoldByUbiquiti: boolean
  isSellerInactive: boolean
  isAdvancedFlowAvailable: boolean
  isForwardedToUI: boolean
  hasParent: boolean
  product: ProductParentCategory
  productImage: string
  seller: Company | null
}

export type KitReturnDevice = {
  index: number
  mac: string
  sku: string
  deviceId: DeviceId | null
  loading: boolean
  error: string
}

export type AfiKitReturn = {
  type: 'afi'
  option: 'whole-kit' | 'meshpoint-only'
  devices: KitReturnDevice[]
}

export type UaG2KitReturn = {
  type: 'ua-g2'
  option: 'not-part-of-kit' | 'ua-g2-sk' | 'ua-g2-sk-pro' | null
  returnSpecifics: 'whole-kit' | 'just-this-device' | null
  devices: KitReturnDevice[]
}

export type KitReturn = AfiKitReturn | UaG2KitReturn

export type KitReturnType = KitReturn['type']

export const KitReturnBaseSku: string[] = ['ua-hub-door', 'ua-g2-pro', 'ua-g2']
